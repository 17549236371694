import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from 'react-infinite-scroll-component';
import { FaCaretUp, FaCaretDown, FaTimes } from 'react-icons/fa';
import Spinner from '../components/spinner';
import { 
  loadMetaData,
  loadTokenListByOffset,
  loadTotalSold,
  loadRarityData,
  loadTokenByID
} from '../redux/common/commonActions';
import '../styles/home.css';
import { camelCase } from 'lodash';

const BASE_URL = "http://www.invaderz.co.uk/INVADERZ-TOKENS/";

const Home = () => {
  const dispatch = useDispatch();
  const common = useSelector((state) => state.common);
  const [modalOpen, setModalOpen] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [modalToken, setModalToken] = useState({});
  const [tokenList, setTokenList] = useState([]);
  const [pageIndex, setPageIndex] = useState(1);
  const [filterObj, setFilterObj] = useState({});
  const [datas, setDatas] = useState([]);
  const countPerPage = 100;

  useEffect(async () => {
    dispatch(loadTotalSold());
    await dispatch(loadMetaData());
    await dispatch(loadRarityData());
  }, []);

  useEffect(() => {
    if (!common.isLoadingMetaData) {
      setDatas(common.metaData.maininfo);
    }
  }, [common.isLoadingMetaData]);

  useEffect(() => {
    if (datas.length > 0)
      fetchTokenList();
  }, [datas])

  const fetchTokenList = (type = 'nextPage') => {
    if (type === 'nextPage') {
      // load next page data
      setTokenList([...tokenList, ...datas.slice((pageIndex - 1) * countPerPage, pageIndex * countPerPage)]);
      setPageIndex((prevState) => prevState + 1);
    }
  }

  const openDetailPopup = async (item) => {
    // console.log(item, 'modaltoken');
    await dispatch(loadTokenByID(parseInt(item.invaders.split('_')[1].split('.')[0])));
    setModalToken(item);
    setModalOpen(true);
    // console.log(common.token, 'commontoken');
  }

  const onWalletClick = (address) => {
    window.open(`/wallet?address=${address}`, "_blank");
  }

  const onFilterClick = async (e) => {
    let temp = filterObj;
    const subtrait = e.target.id.split("_")[1];
    const traittype = e.target.id.split("_")[0];
    if(e.target.checked === true){
      if(temp[camelCase(traittype)] == undefined){
        temp[camelCase(traittype)] = [];
        temp[camelCase(traittype)].push(subtrait);
      } else {
        temp[camelCase(traittype)].push(subtrait);
      }
    } else {
      if(temp[camelCase(traittype)].includes(subtrait) === true){
        temp[camelCase(traittype)] = temp[camelCase(traittype)].filter((item, index) => item != subtrait);
        if(temp[camelCase(traittype)].length == 0){
          delete temp[camelCase(traittype)];
        }
      }
    }
    setFilterObj(temp);
    let data = common.metaData.maininfo;
    if(Object.keys(temp).length != 0){
      Object.keys(temp).map((item, index) => {
        data = data.filter((it, idx) => temp[camelCase(item)].includes(it[camelCase(item)]));
      })
    }
    await setTokenList([]);
    await setPageIndex(1);
    await setDatas([...data]);
  }

  const getCamelCase = (str) => {
    if (str.includes("blocks")) {
      return "blockNumber";
    } else if (str.includes("colour")) {
      return "colours";
    } else if (str.includes("name") != true) {
      return "elementsOutOf10000";
    }
  }

  const getRarityAmount = (item, element) => { 
    const itemIndex = getCamelCase(item);
    let eleData = element;
    let res = "";
    if (eleData.toLowerCase().includes("block")) {
			eleData = eleData.split(' ')[0];
		  } else if (eleData.toLowerCase().includes("#")) {
				  eleData.replace('#', '');
			  }
    // console.log(item, itemIndex, eleData, "dataadtatasdat");
    common.rarityData[itemIndex].map((ele, index) => {
      // console.log(ele.name, eleData, "elementdata");
      if (ele.name == eleData) {
        if (itemIndex == "blockNumber") {
          res = ele.blocksRarity;
        } else if (itemIndex == "colours") {
          res = ele.colourRarity;
        } else if (itemIndex == "elementsOutOf10000") {
          res = ele.elementsRarity;
        }
      }
    });
    return res;
  }

  return (
    <div className='mt-16 relative'>
      { common.isLoadingMetaData || common.isLoadingRarityData ? <Spinner /> :
      <>
      <div className='text-white'>
        <div className='w-full text-center'>
          <div className='my-8'>{(common.sold_count) * 1.0 / common.metaData.maininfo.length * 100}% reserved</div>
          {/* Attributes Filter */}
          <div className='flex flex-row items-center justify-center cursor-pointer' onClick={() => setFilterOpen((prevState) => !prevState)}>
            <div className='font-medium'>Filters</div>
            <div className='mt-1 ml-1'>
              {filterOpen ? <FaCaretDown /> : <FaCaretUp />}
            </div>
          </div>
          <div className="w-full flex justify-center">
            {filterOpen && <div className='flex flex-row justify-center filterDown gap-5 flex-wrap w-2/4'>
              {
                Object.keys(common.metaData.traits).map((key, index) => {
                  if(key.includes("name") != true) {
                    return (
                      <div key={index}>
                        <div className='text-left font-bold text-[#dcdd6f]'>{key}</div>
                        <div className='h-[110px] w-[200px] overflow-y-auto flex flex-col gap-1 text-left nice-scroll'>
                          {
                            common.metaData.traits[camelCase(key)].map((item, idx) => {
                              return (
                                <div className='flex flex-row items-center gap-1' key={idx}>
                                  <input type="checkbox" id={key + "_" + item} onClick={(e) => onFilterClick(e)} className="min-w-[16px] min-h-[16px]" />
                                  <p className='md:max-w-[500px] w-auto' for={key + "_" + item}>{item}</p>
                                </div>
                              );
                            })
                          }
                        </div>
                      </div>
                    )
                  }
                })
              }
            </div>}
          </div>
        </div>
        {/* Token Image Gallery */}
        <div className=''>
          {common.isLoadingMetaData ? <Spinner /> : 
            <InfiniteScroll
              dataLength={tokenList.length}
              next={fetchTokenList}
              hasMore={true}
              className='flex flex-row flex-wrap mt-4'
            >
              {
                tokenList.map((item, index) => {
                  return (
                    <img
                      key={index}
                      src={BASE_URL + item.invaders}
                      className="w-[50%] md:w-[25%] lg:w-[20%] 2xl:w-[10%] cursor-pointer border border-gray-500 border-opacity-80"
                      onClick={() => openDetailPopup(item)}
                    />
                  )
                })
              }
            </InfiniteScroll>
          }
        </div>
      </div>
      {/* Token Detail Popup */}
      {modalOpen && common.isLoadingToken == false && <div className='fixed inset-0 flex items-center justify-center'>
        <div className='fixed inset-0 modalBack bg-[#000000] bg-opacity-75 z-0' onClick={() => setModalOpen(false)}></div>
        <div className='elementFadeUp relative w-[400px] min-h-[100px] bg-[#000000] border border-black border-opacity-30 rounded-lg z-10 flex flex-col text-center px-5 pt-12 pb-5 items-center detailInfo'>
          <img src={BASE_URL + modalToken.invaders} className="w-[250px]" />
          <div className="text-[#ffffff] text-lg mt-2 font-bold">Invaderz No.</div>
          <div className="text-[#ffffff] text-3xl font-bold">{modalToken.invaders.split("_")[1].split(".")[0]}</div>
          <div className="text-[#ffffff] text-base font-bold w-[200px] text-ellipsis whitespace-nowrap overflow-hidden">{modalToken.name}</div>
          <div className="text-[#ffffff] text-base mt-2">RESERVED BY:</div>
          <div className="text-[#ffffff] text-base mt-2 cursor-pointer break-all" onClick={() => onWalletClick(common.token.owner)}>{common.token.owner}</div>
          <div className="w-full px-6 font-bold text-[#334455] flex flex-row justify-between mt-5">
            
          </div>
          
          <div className="text-[#ffffff] text-base mt-4">
            <a href={`/info?number=${parseInt(modalToken.invaders.split("_")[1].split(".")[0])}`} target="_blank" >*MORE INFORMATION*</a>
          </div>
          <div className='w-full flex justify-end absolute top-4 right-4 text-lg cursor-pointer' onClick={() => setModalOpen(false)}>
            <FaTimes />
          </div>
        </div>
      </div>}
      </>
      }
    </div>
  );
}

export default Home;