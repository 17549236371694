import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { 
	loadTokenListByWallet,
	loadMetaData
} from '../redux/common/commonActions';
import Spinner from "../components/spinner";
import { camelCase } from "lodash";
import { FaCaretUp, FaCaretDown } from 'react-icons/fa';

const BASE_URL = "http://www.invaderz.co.uk/INVADERZ-TOKENS/";

const Wallet = () => {
	const dispatch = useDispatch();
	const [wallet, setWallet] = useState('');
	const common = useSelector((state) => state.common);
  const [filterOpen, setFilterOpen] = useState(false);
	const [filterObj, setFilterObj] = useState({});
  const [datas, setDatas] = useState([]);

	useEffect(async () => {
		const params = new URLSearchParams(window.location.search);
		await setWallet(params.get("address"));
		dispatch(loadTokenListByWallet(params.get("address").toString()));
		await dispatch(loadMetaData());
	}, []);

	useEffect(() => {
    if (!common.isLoadingWalletTokenList) {
      setDatas(common.walletTokenList);
    }
	}, [common.isLoadingWalletTokenList]);

	const onImgClick = (id) => {
		window.open(`/info?number=${id}`, "_self");
	}

	const onFilterClick = async (e) => {
		let temp = filterObj;
		const subtrait = e.target.id.split("_")[1];
		const traittype = e.target.id.split("_")[0];
		if(e.target.checked === true){
		if(temp[camelCase(traittype)] == undefined){
			temp[camelCase(traittype)] = [];
			temp[camelCase(traittype)].push(subtrait);
		} else {
			temp[camelCase(traittype)].push(subtrait);
		}
		} else {
		if(temp[camelCase(traittype)].includes(subtrait) === true){
			temp[camelCase(traittype)] = temp[camelCase(traittype)].filter((item, index) => item != subtrait);
			if(temp[camelCase(traittype)].length == 0){
			delete temp[camelCase(traittype)];
			}
		}
		}
		setFilterObj(temp);
			let data = common.walletTokenList;
		if(Object.keys(temp).length != 0){
			Object.keys(temp).map((item, index) => {
						data = data.filter((it, idx) => temp[camelCase(item)].includes(common.metaData.maininfo[it.id - 1][camelCase(item)]));
			})
		}
			await setDatas([...data]);
	}

	return (
			<div>
				{
					common.isLoadingWalletTokenList || common.isLoadingMetaData ? <Spinner /> :
					<div className="w-full flex text-center mt-16 pb-4 text-white flex-col items-center">
						<div className='text-2xl font-bold'>Wallet</div>
						<div className='mt-8'>Address:</div>
						<div className="mb-5 w-full px-5 break-words">{wallet}</div>
						{/* Attributes Filter */}
						<div className='flex flex-row items-center justify-center cursor-pointer' onClick={() => setFilterOpen((prevState) => !prevState)}>
							<div className='font-medium'>Filters</div>
							<div className='mt-1 ml-1'>
								{filterOpen ? <FaCaretDown /> : <FaCaretUp />}
							</div>
						</div>
						<div className="w-full flex justify-center mb-2">
							{filterOpen && <div className='flex flex-row justify-center filterDown gap-5 flex-wrap w-2/4'>
								{
									Object.keys(common.metaData.traits).map((key, index) => {
										if(key.includes("name") != true) {
											return (
												<div key={index}>
													<div className='text-left font-bold text-[#dcdd6f]'>{key}</div>
													<div className='h-[110px] w-[200px] overflow-y-auto flex flex-col gap-1 text-left nice-scroll'>
														{
															common.metaData.traits[camelCase(key)].map((item, idx) => {
																return (
																	<div className='flex flex-row items-center gap-1' key={idx}>
																		<input type="checkbox" id={key + "_" + item} onClick={(e) => onFilterClick(e)}/>
																		<label for={key + "_" + item}>{item}</label>
																	</div>
																);
															})
														}
													</div>
												</div>
											)
										}
									})
								}
							</div>}
						</div>
						{ datas.length != 0 ? <div className="flex flex-row flex-wrap w-[50%] justify-center">
							{
								datas.map((item, index) => {
									return (
										<div className='flex flex-col mb-5' key={item._id}>
											<img
												key={item._id}
												src={item.imageUrl}
												className='w-[150px] h-[150px] cursor-pointer border border-gray-500 border-opacity-80'
												onClick={() => onImgClick(item.title.split(' ')[1])}
											/>
											<div>{item.title.split(' ')[1]}</div>
										</div>
									)
								})
							}
						</div> : <div>There are no match items.</div>
						}
						<div className="mt-5 underline"><a href="http://www.invaderz.space">home</a></div>
					</div>
				}
			</div>
	)
}

export default Wallet;