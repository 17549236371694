import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { 
	loadMetaData,
	loadTokenByID,
	loadRarityData
} from '../redux/common/commonActions';
import Spinner from "../components/spinner";
import { camelCase } from "lodash";

const BASE_URL = "http://www.invaderz.co.uk/INVADERZ-TOKENS/";

const Info = () => {
	const dispatch = useDispatch();
	const [id, setId] = useState(0);
	const common = useSelector((state) => state.common);

	useEffect(async () => {
		const params = new URLSearchParams(window.location.search);
		dispatch(loadTokenByID(params.get("number")));
		await setId(params.get("number"));
		await dispatch(loadRarityData());
		await dispatch(loadMetaData());
	}, [])

	const onWalletClick = (address) => {
		window.open(`/wallet?address=${address}`, "_self");
	}

  const getCamelCase = (str) => {
    if (str.includes("blocks")) {
      return "blockNumber";
    } else if (str.includes("colour")) {
      return "colours";
    } else if (str.includes("name") != true) {
			return "elementsOutOf10000";
		} else {
			return "none";
		}
  }

	const getTotalRarityAmount = () => {
		let result = 1.0;
		Object.keys(common.metaData.maininfo[id - 1]).map((item, index) => {
			const itemIndex = getCamelCase(item.toLowerCase());
			if (itemIndex != 'none') {
				if (common.metaData.maininfo[id - 1][camelCase(item)].length > 0) {
					let amount = common.metaData.maininfo[id - 1][camelCase(item)];
					if (amount.toLowerCase().includes("block")) {
						amount = amount.split(' ')[0];
					}
					// console.log("data", common.metaData.maininfo[id - 1][camelCase(item)], item, itemIndex, amount);
					common.rarityData[itemIndex].map(( ele, ind ) => {
						if (ele.name.toLowerCase() == amount.toLowerCase()) {
							// console.log(ele, amount, "eleandamount");
							if (itemIndex == "blockNumber") {
								result +=  parseFloat(ele.blocksRarity.split('%')[0]);
								// result = result * parseFloat(ele.blocksRarity.split('%')[0]) / 100.0;
							} else if (itemIndex == "colours") {
								result += parseFloat(ele.colourRarity.split('%')[0]);
								// result = result * parseFloat(ele.colourRarity.split('%')[0]) / 100.0;
							} else if (itemIndex == "elementsOutOf10000") {
								result += parseFloat(ele.elementsRarity.split('%')[0]);
								// result = result * parseFloat(ele.elementsRarity.split('%')[0]) / 100.0;
							}
						}
					})
				}
			}
		});
		// console.log(result, 'result');
		return result;
	}

	const getRarityAmount = (item, element) => {
		const itemIndex = getCamelCase(item);
		let eleData = element;
		let res = "";
		if (eleData.toLowerCase().includes("block")) {
			eleData = eleData.split(' ')[0];
		  } else if (eleData.toLowerCase().includes("#")) {
				  eleData.replace('#', '');
			  }
		// console.log(item, itemIndex, eleData, "dataadtatasdat");
		common.rarityData[itemIndex].map((ele, index) => {
		  // console.log(ele.name, eleData, "elementdata");
		  if (ele.name == eleData) {
			if (itemIndex == "blockNumber") {
			  res = ele.blocksRarity;
			} else if (itemIndex == "colours") {
			  res = ele.colourRarity;
			} else if (itemIndex == "elementsOutOf10000") {
			  res = ele.elementsRarity;
			}
		  }
		});
		return res;
	  }
	let temp = 0;

	return (
		<div className="w-full flex justify-center mt-12">
			{ common.isLoadingMetaData || common.isLoadingRarityData ? 
				<Spinner />
				:<div className="md:w-[606px] w-full py-4 px-4 md:px-32 mt-8 infoContainer text-center text-white flex flex-col items-center">
					<img src={BASE_URL + common.metaData.maininfo[id - 1].invaders} className="w-[250px]" />
					<div className="text-lg mt-2 font-bold">Invaderz No.</div>
					<div className="text-3xl font-bold">{id}</div>
					<div className="text-xl font-bold w-[200px] text-ellipsis whitespace-nowrap overflow-hidden">{common.metaData.maininfo[id - 1].name}</div>
					<div className="text-[#ffffff] text-base mt-2">RESERVED BY:</div>
						<div className="text-[#ffffff] text-base mt-2 cursor-pointer break-words w-full" onClick={() => onWalletClick(common.token.owner)}>{common.token.owner}</div>
					<div className="mt-3">Traits</div>
					<div className="w-full mt-2 flex flex-col border border-solid border-white border-opacity-5">
						<div className="w-full flex flex-row justify-between font-bold text-sm py-1">
							<div>Trait</div>
							<div>Value</div>
							<div>Rarity</div>
						</div>
						{
							Object.keys(common.metaData.maininfo[id - 1]).map((item, index) => {
								if(index > 0 && item.includes("colour") !== true && item.includes("name") !== true && common.metaData.maininfo[id - 1][camelCase(item)].length > 1) {
									return (
										<div className={(temp++) % 2 === 1 ? "w-full flex flex-row justify-between bg-[#F0E5E5] text-black text-sm py-1 px-2" : "w-full flex flex-row justify-between text-sm py-1 px-2"} key={index}>
											<div>{item}</div>
											<div>
												{
													common.metaData.maininfo[id - 1][camelCase(item)].length > 1
													? <div>{common.metaData.maininfo[id - 1][camelCase(item)]}</div>
													: <div>None</div>
												}
											</div>
											<div>{common.metaData.maininfo[id - 1][camelCase(item)].length > 1
													? <div>{getRarityAmount(item, common.metaData.maininfo[id - 1][camelCase(item)])}</div>
													: <div>None</div>}
											</div>
										</div>
									)
								}
							})
						}
					</div>
					<div className="w-[250px] mt-10 flex flex-col border border-solid border-white border-opacity-5">
						<div className="py-1">Colour</div>
						{
							Object.keys(common.metaData.maininfo[id - 1]).map((item, index) => {
								if(item.includes("colour") && common.metaData.maininfo[id - 1][camelCase(item)].length > 1){
									return (
										<div className="flex flex-row justify-between text-white px-1" key={index} style={{backgroundColor:common.metaData.maininfo[id - 1][camelCase(item)]}}>
											<div>{item}</div>
											<div>{common.metaData.maininfo[id - 1][camelCase(item)]}</div>
											{/* <div>{getRarityAmount(item, common.metaData.maininfo[id - 1][camelCase(item)])}</div> */}
										</div>
									)
								}
							})
						}
					</div>
					
				</div>
			}
		</div>
	)
}

export default Info;