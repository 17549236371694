import { HiOutlineMail } from 'react-icons/hi';
import { FaTelegram, FaTwitter } from 'react-icons/fa';
import $ from 'jquery';

$(function () {
    $("#stringMe").on("click", function () {
        var trade = $("#trade");
        var number = $("#number");
        var amount = $("#amount");
        var wall = $("#wall");
        var string = "<p style='word-break: break-all;' id='copytx'>" + number.val() + " for " + amount.val() + " xrd to " + wall.val() + "</p>";
        $(string).appendTo("#stringedUp")
    })
});

function copyToClipboard2(element) {
    var $temp = $("<input>");
    $("body").append($temp);
    $temp.val($(element).text()).select();
    document.execCommand("copy");
    $temp.remove()
}
const Instruction = () => {
    return (
        <div className="text-white w-full flex justify-center py-5">
            <div className="md:w-[800px] w-full md:px-600 px-5 text-center">
                <div className="uppercase text-3xl">INVADERZ</div>
                <div className="mt-10">
                    <div className="text-2xl">Instructions for INVADERZ Purchase, Listing and Delisting</div>
                   
                    <div className="mt-5 text-sm text-centre text-slate-300 leading-6">
                        <br /> SOLD OUT - The first NFT on the Radix DLT to break 24 hours (20 HOURS 33 MINS without a pre-sale) :- A collection of 10,000 NFT's on the Radix DLT.INVADERZ CONSIST OF 10000 UNIQUE NFTS for minting on the Radix DLT.<br />

                        <a href="https://www.vikingland.io/collection/Invaderz" target="_blank" className="break-words">Please goto <b>Vikingland</b> to purchase please in addition to the instructions below.</a>

                       
                    </div>
                </div>
                <div className="mt-10">
                    
                    <div className='mt-2'>
                        <div className=' text-2xl text-center'>
                        <a href="https://www.vikingland.io/collection/Horrible%20Heads" target="_blank" className="break-words">For sales please visit <b>Vikingland</b> </a><br />
                        </div>
                        <div className='text-slate-300 text-left'>
                        
                        </div>
                    </div>
                    
                    <div className="mt-10">
                        <div className="text-2xl">List your NFT</div>
                        <div className="mt-5 text-sm text-left text-slate-300 leading-6">

                        If you want to list your NFT for sale on <a href="https://www.vikingland.io/collection/Invaderz" target="_blank" className="break-words"> <b>VikingLand marketplace.</b>  Please follow steps below.</a><br /><br />

                        You have to send your NFT token to the collection wallet <br /><br /><b>rdx1qsp023kpa8mrf76ft7pwagh0887cljnpt3tmqvwpns07ldcq2x6gx3sy4lvdz</b> <br /><br />with transaction message "list (Invaderz) for (NFT price)"<br /><br />

                        Example 1:
                        You want to list your Invaderz  NFT Number 10 for 400 XRD. You have to send a Invaderz token to the collection wallet with a transaction message "list Invaderz 10 for 400 xrd"<br /><br />

                        Example 2:
                        You want to list your Invaderz  NFT Number 666 for 1000 XRD. You have to send a Invaderz token to the collection wallet with transaction message "list Invaderz 666 for 1000 xrd"<br /><br />

                        IMPORTANT:
                        You must be sure to send the token from the wallet you bought it with. Otherwise, the transaction cannot be assigned.<br /><br />

                        Fees:
                        Please be aware that there is trading fee for using the marketplace and a royalty fee from the NFT creator.

                        
                        </div>
                    </div>
                    <div className="mt-10">
                        <div className="text-2xl">Delisting your NFT</div>
                        <div className="mt-5 text-sm text-left text-slate-300 leading-6">

                        You can also delist your NFT at any time from the marketplace:<br /><br />

                        Send 1 XRD to the collection wallet address <br /><br /><b>rdx1qsp023kpa8mrf76ft7pwagh0887cljnpt3tmqvwpns07ldcq2x6gx3sy4lvdz</b> <br /><br />with transaction message "delist (Invaderz)"<br /><br />

                        Example:
                        You want to delist Invaderz NFT Number 666. You have to send 1 XRD to collection wallet with transaction message "delist Invaderz 666"<br /><br />

                        IMPORTANT:
                        You must be sure to delist from the wallet you bought it with. Otherwise, the transaction cannot be assigned.           
                        </div>
                    </div>
                    <div className="mt-10">
                        <div className="text-2xl">What are RAIDERZ?</div>
                        <div className="mt-5 text-sm text-left text-slate-300 leading-6">

                        🚨 INVADERZ are split into 2023 Species called RAIDERZ? 🚨 <br></br><br></br>

                        The RAIDERZ are replications of each other (ALIEN INVADERZ), but different in looks! (Traits determined) <br></br><br></br>

                        🪐 The RAIDERZ all come from different Planets and are only classed as RAIDERZ when they are a Species of 2 or more. The number of groups are determined below. 🪐 <br></br><br></br>

                        👾 DUPLICATE RAIDERZ GROUPS 👾 <br></br><br></br>

                        -------------------------------------------------------<br></br><br></br>

                        👽 RAIDERZ Groups with 2: <b>954</b> 👽<br></br><br></br>

                        👽 RAIDERZ Groups with 3: <b>547</b> 👽 <br></br><br></br>

                        👽 RAIDERZ Groups with 5: <b>225</b> 👽 <br></br><br></br>

                        👽 RAIDERZ Groups with 4: <b>209</b> 👽 <br></br><br></br>

                        👽 RAIDERZ Groups with 6: <b>75</b> 👽  <br></br><br></br>

                        👽 RAIDERZ Groups with 8: <b>4</b> 👽 <br></br><br></br>

                        👽 RAIDERZ Groups with 7: <b>2</b> 👽 <br></br><br></br>

                        👽 RAIDERZ Groups with 10: <b>2</b> 👽 <br></br><br></br>

                        👽 RAIDERZ Group with 16: <b>1</b> (you may have to collaborate for this group) 👽 <br></br><br></br>

                        👽 RAIDERZ Group with 27: <b>1</b> (you may have to collaborate for this group) 👽 <br></br><br></br>

                        👽 RAIDERZ Group with 29: <b>1</b> (you may have to collaborate for this group) 👽 <br></br><br></br>

                        👽 RAIDERZ Group with 59: <b>1</b> (you may have to collaborate for this group) 👽 <br></br><br></br>

                        RAIDERS ARE HERE: (we will make an easier format soon) 👾<br></br><br></br>

                        --------------------------------------------

                        <a href="https://docs.google.com/spreadsheets/d/1CFL_4ooqD6-Z5_ZqyThvFTSvM9LYyU20zd5jzsxsIZ4/edit#gid=1991760706" target="_blank" className="break-words"><b>References to RAIDERZ click here</b></a>  --------------------------------------------<br></br><br></br>

                    

                        Quickest way to search 🧐 : open the link above. Search for one of your INVADERZ by ID - using: <br></br><br></br><a href="https://www.invaderz.space" target="_blank" className="break-words"><b>INVADERZ</b></a> or  <a href="https://www.vikingland.net/collection/Invaderz" target="_blank" className="break-words"><b>Vikingland website</b></a><br></br><br></br>
                        
                        look at the 👥 RAIDERZ Group ID on the left in the google sheet (Column 1), and check the rest of them in that group. <br></br><br></br>

                        If you do not have a set you can search availability on  <a href="https://www.vikingland.net/collection/Invaderz" target="_blank" className="break-words"><b>Vikingland website</b></a> to complete the set or Trade with your fellow ALIEN enthusiasts. More to come....👾👾👾<br></br><br></br>


                        
                        </div>
                    </div>
                </div>
                <div className="mt-10">
                    <div className="text-2xl">DISCLAIMER</div>
                    <div className="mt-5 text-sm text-centre text-slate-300 leading-6">
                        The INVADERZ Team will NOT be held responsible if your transactions are incorrect.
                        <br />
                        Please make sure our address is accurate - see below. <br /><br />
                        You are advised to NOT use any ethereum wallets for sending XRD. Please use the Offical Radix Wallet <br />
                        <br /><br />
                        
                    </div>
                </div>
                <div className="mt-10">
                    <div className="text-2xl">FIND US HERE FOR VERIFICATION:</div>
                    <div className="mt-5 text-sm text-centre text-slate-300 leading-6">
                        <a href="https://explorer.radixdlt.com/#/accounts/rdx1qspctzmkl43f6teedl29uv39ktwecaaxcm4ex0ppf2qjeajju8ksw4gsehhds" target="_blank" className="break-words">Invaderz Collection Wallet</a>
                    </div>
                </div>
                <div className="mt-10">
                    <div className="text-2xl">USE THE OFFICIAL RADIX DLT WALLET HERE:</div>
                    <div className="mt-5 text-sm text-centre text-slate-300 leading-6">
                        <a href="https://wallet.radixdlt.com" target="_blank" className="break-words">https://wallet.radixdlt.com</a>
                    </div>
                </div>
                <div className="mt-10">
                    <div className="text-2xl">Only use Radix's Native Token (XRD):</div>
                    <div className="mt-5 text-sm text-centre text-slate-300 leading-6">
                        <div>The official Radix Wallet does NOT except ethereum wrapped Radix Tokens (EXRD).</div>
                        <a href="https://learn.radixdlt.com/article/how-can-i-purchase-xrd-and-exrd-tokens" target="_blank" className="break-words">https://learn.radixdlt.com/article/how-can-i-purchase-xrd-and-exrd-tokens</a>
                    </div>
                </div>
                <div className="mt-10">
                    <div className="text-2xl">Reach Us:</div>
                    <div className="mt-5 text-slate-300 flex flex-row justify-center gap-10 text-3xl">
                        <a href='https://t.me/INVADERZ_NFT' target="_blank"><FaTelegram /></a>
                        <a href="https://twitter.com/invaderz_nft" target="_blank"><FaTwitter /></a>
                        <a href='mailto:rotten@horribleheads.co.uk'><HiOutlineMail /></a>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Instruction;