const initialState = {
  metaData: {},
  isLoadingMetaData: true,
  isLoadingTokenList: true,
  isLoadingToken: true,
  isLoadingWalletTokenList: true,
  tokenlist: [],
  walletTokenList: [],
  token: {},
  sold_count: 0,
  isLoadingRarityData: false,
  rarityData: []
};

const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case "START_LOAD_METADATA":
      return {
        ...state,
        isLoadingMetaData: true
      };
    case "LOAD_METADATA":
      return {
        ...state,
        metaData: action.payload,
        isLoadingMetaData: false
      };
    case 'START_LOAD_TOKENLIST':
      return {
        ...state,
        isLoadingTokenList: true
      };
    case 'START_LOAD_RARITYDATA':
      return {
        ...state,
        isLoadingRarityData: true
      };
    case 'LOAD_RARITYDATA':
      return {
        ...state,
        isLoadingRarityData: false,
        rarityData: action.payload
      }
    case 'LOAD_TOKENLIST':
      return {
        ...state,
        tokenlist: action.payload,
        isLoadingTokenList: false
      };
    case 'START_LOAD_TOKEN':
      return {
        ...state,
        isLoadingToken: true
      }
    case 'LOAD_TOKEN':
      return {
        ...state,
        isLoadingToken: false,
        token: action.payload
      }
    case 'START_LOAD_WALLETTOKEN':
      return {
        ...state,
        isLoadingWalletTokenList: true,
      }
    case 'LOAD_WALLETTOKEN':
      return {
        ...state,
        walletTokenList: action.payload,
        isLoadingWalletTokenList: false
      }
    case 'LOAD_SOLD':
      return {
        ...state,
        sold_count: action.payload
      }
    default:
      return state;
  }
};

export default commonReducer;
